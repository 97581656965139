import React, { useContext, useEffect } from 'react'
import { store } from '../../utils/store';
import styled from 'styled-components';
import { Appointment, Attendance, BannerPaciente, Benefits, Download, Platform, Search } from '../organisms';
import { Footer } from '../molecules';
import { ParallaxProvider } from 'react-scroll-parallax';

const Scroll = require('react-scroll');
const scroll = Scroll.animateScroll;

export default function Paciente() {

  const { patientContent } = useContext(store);

  useEffect(() => {
    // scroll.scrollToTop();
  }, []);

  return (
    <>
      {patientContent &&
        <ParallaxProvider>
          <BannerPaciente patientContent={patientContent}/>
          <Benefits patientContent={patientContent}/>
          <Search patientContent={patientContent}/>
          <Appointment patientContent={patientContent}/>
          <Attendance patientContent={patientContent}/>
          <Platform patientContent={patientContent}/>
          <Download patientContent={patientContent}/>
          <Footer patientContent={patientContent}/>
        </ParallaxProvider>
      }
    </>
  )
}