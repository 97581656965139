import React from 'react'
import styled, { css } from 'styled-components';
import { Container, Section, Text12, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function Download({ patientContent }) {

  if (patientContent) {
    return (
      <Section className={patientContent.content && patientContent.content.download_label} backgroundColor="#FFF">
        <DivColumn style={{ alignItems: 'center' }}>
          {(patientContent.content && patientContent.content.download_title) &&
            <Wrapper noHeight both >
              <BigTitle dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.download_title }} />
            </Wrapper>
          }
          <FloatBoxDownload>
            <DivColumn>
              <Intro
                smallTitleColor="#77EDA6"
                smallTitle={patientContent.content && patientContent.content.download_label}
                titleSize="H3"
                title={patientContent.content && patientContent.content.download_text1}
                titleColor="#008FA6"
                titleMaxWidth="490px"
                description={patientContent.content && patientContent.content.download_text2}
                descriptionSize={18}
                descriptionMaxWidth="470px"
                descriptionColor="#9AA1AC"
              />
              <ContainerDownload>
                <Text14 color="#3B3F45" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.home_text4 }} />
                <DivButtons>
                  <Button href={patientContent.content && patientContent.content.download_apple_store_url} target='_blank'>
                    <Icon src="/static/images/logo_apple.svg" />
                    <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "App Store" }} />
                  </Button>
                  <Button href={patientContent.content && patientContent.content.download_google_play_url} target='_blank'>
                    <Icon src="/static/images/logo_playstore.svg" />
                    <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "Google Play" }} />
                  </Button>
                </DivButtons>
              </ContainerDownload>
            </DivColumn>
            <Image src={patientContent.content && patientContent.content.download_image} />
          </FloatBoxDownload>
        </DivColumn>
      </Section>
    )
  }
  return null;

}

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const BigTitle = styled.div`
  font-family: 'SFProDisplayBold', sans-serif;
  font-size: calc(60px + (125 - 60) * ((100vw - 375px) / (1920 - 375)));	
  text-align: center;
  line-height: 1;
  color: #77EDA6;
  margin: 0 15px;
`;
const FloatBoxDownload = styled.div`
  margin: 0 15px;
  margin-bottom: -36px;
  width: 1196px;
  max-width: calc( 100% - 30px);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: calc(40px + (80 - 40) * ((100vw - 375px) / (1920 - 375))) calc(20px + (100 - 20) * ((100vw - 375px) / (1920 - 375))) calc(35px + (70 - 35) * ((100vw - 375px) / (1920 - 375)));
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  box-sizing: border-box;
  @media screen and (max-width: 1000px){
    flex-flow: column-reverse;
  }
`;
const ContainerDownload = styled.div`
  display:flex;
  margin-top:60px;
  flex-flow:column;
`;
const DivButtons = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:8px;
`;
const Button = styled.a`
  width:147px;
  height:40px;
  display:flex;
  flex-flow:row;
  align-items:center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  :first-child{
    margin-right:12px;
  }
  :hover{
    background-color:#E7EBF2;
    cursor:pointer;
  }
`;
const Icon = styled.img`
  height:14px;
  margin-right:8px;
  ${({ footer }) => footer && css`
    width:12px;
    height:12px;
    margin:0;
  `}
`;
const Image = styled.img`
  max-width: 415px;
  object-fit: contain;
  @media screen and (max-width: 1000px){
    margin-bottom: 32px;
  }
`;