import React, { createContext, useState } from 'react';

const store = createContext();
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [lang, setLang] = useState(null);
  const [entryContent, setEntryContent] = useState(null);
  const [patientContent, setPatientContent] = useState(null);
  const [medicContent, setMedicContent] = useState(null);
  const [legalContent, setLegalContent] = useState(null);
  return <Provider value={
    {
      lang, setLang,
      entryContent, setEntryContent,
      patientContent, setPatientContent,
      medicContent, setMedicContent,
      legalContent, setLegalContent
    }
  }>{children}</Provider>;
};

export { store, StateProvider };
