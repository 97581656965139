import React, { useContext } from 'react'
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Container, Section, Text12, Text14, Text18 } from '../../atoms';
import { Intro } from '../../molecules';


export default function Agenda({ medicContent }) {

  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.benefits_label} backgroundColor="#B4FFD2" onTop={true}>
        <Wrapper>
          <DivIntro>
            <Logo src="/static/images/icon_calendario.svg" />
            <Intro
              centerContainer
              smallTitleColor="#77EDA6"
              smallTitle={medicContent.content && medicContent.content.benefits_label}
              titleSize="H4"
              title={medicContent.content && medicContent.content.benefits_text1}
              titleColor="#008FA6"
              titleMaxWidth="412px"
              titleTextAlign="center"
              description={medicContent.content && medicContent.content.benefits_text2}
              descriptionSize={18}
              descriptionMaxWidth="470px"
              descriptionColor="#9AA1AC"
              descriptionTextAlign="center"
            />
            <Image src={medicContent.content && medicContent.content.benefits_image}/>
          </DivIntro>
        </Wrapper>
      </Section>
    )
  }
  return null;
}

const Wrapper = styled.div`
  padding-top: calc(70px + (130 - 70) * ((100vw - 375px) / (1920 - 375)));
  height: 100%;
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 20px;
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 600px){
    padding: 0 15px;
    box-sizing: border-box;
  }
`;
const Image = styled.img`
  max-width: 100%;
  width: 1200px;
  margin-bottom: calc(-45px + (-180 - (-45)) * ((100vw - 375px) / (1920 - 375)));
`;