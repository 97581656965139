import React, { useContext, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { setLanguage } from '../../../utils/language';
import { store } from '../../../utils/store';
import {
  Text14,
  Text16,
  Text18
} from '../../atoms';
import { Link } from 'react-scroll';
import { Link as Route } from 'react-router-dom';
import ModalMenu from '../modalMenu';
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { useI18Zen } from '@keegpt/i18zen';

export default function Navbar(props) {

  const { changeLocale } = useI18Zen();

  const [cookies, setCookie, removeCookie] = useCookies(['language']);

  const { lang, setLang, patientContent, medicContent } = useContext(store);

  let content = props.content ? props.content : {};
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  let pathName = history.location.pathname;

  //languages

  const [options, setOptions] = useState([]);

  const changeLang = (option) => {
    setLang(option.value);
    setLanguage(option.value);
    setCookie("language", option.value);
    changeLocale(option.value);
    if (isOpen === true) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (props.languages && props.languages.length > 0) {
      let temp = [];
      for (let i = 0; i < props.languages.length; i++) {
        temp.push({
          value: props.languages[i].code,
          label: props.languages[i].code
        })
      }
      setOptions(temp);
    }
  }, [props]);

  return (
    <DivNav>
      <DivRowSpace>
        <DivRow center>
          <Route to="/">
            <Logo src={(patientContent.content && patientContent.content.logo) || (medicContent.content && medicContent.content.logo)} />
          </Route>
        </DivRow>
        <DivRowMenu>
          {(pathName === '/paciente' && patientContent && patientContent.content) &&
            <DivSections>
              <DivSection>
                {patientContent.content.home_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.home_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.home_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.benefits_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.benefits_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.benefits_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.search_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.search_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.search_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.appointment_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.appointment_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.appointment_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.service_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.service_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.service_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.platform_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.platform_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.platform_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.download_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.download_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.download_label }}
                    />
                  </TitleSections>
                }
              </DivSection>
            </DivSections>
          }
          {(pathName === '/medico' && medicContent && medicContent.content) &&
            <DivSections>
              <DivSection>
                {medicContent.content.home_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.home_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.home_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.benefits_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.benefits_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.benefits_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.fidelity_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.fidelity_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.fidelity_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.payments_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.payments_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.payments_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.billing_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.billing_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.billing_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.iosandroid_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.iosandroid_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.iosandroid_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.clients_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.clients_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.clients_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.files_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.files_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.files_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.notifications_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.notifications_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.notifications_label }}
                    />
                  </TitleSections>
                }
              </DivSection>
            </DivSections>
          }
        </DivRowMenu>
        <ButtonModal onClick={() => setIsOpen(!isOpen)}>
          <Icon src={isOpen ? "/static/images/menu_close.svg" : "/static/images/menu_button.svg"} />
        </ButtonModal>
      </DivRowSpace>
      <DivRowMenu>
        {pathName === '/paciente' &&
          <DivButtonChangeUrl>
            <Route to="/medico">
              <ButtonChangeUrl>
                <Text14
                  color='#fff'
                  dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.header_button_text }}
                />
              </ButtonChangeUrl>
            </Route>
          </DivButtonChangeUrl>
        }
        {pathName === '/medico' &&
          <DivButtonChangeUrl>
            <Route to="/paciente">
              <ButtonChangeUrl>
                <Text14
                  color='#fff'
                  dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.header_button_text }}
                />
              </ButtonChangeUrl>
            </Route>
          </DivButtonChangeUrl>
        }
      </DivRowMenu>

      <ModalMenu isOpen={isOpen} setIsOpen={setIsOpen} content={content}>
        <DivMenuModal>
          {(pathName === '/paciente' && patientContent && patientContent.content) &&
            <DivSections>
              <DivSection>
                {patientContent.content.home_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.home_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.home_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.benefits_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.benefits_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.benefits_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.search_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.search_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.search_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.appointment_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.appointment_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.appointment_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.service_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.service_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.service_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.platform_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.platform_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.platform_label }}
                    />
                  </TitleSections>
                }
                {patientContent.content.download_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={patientContent.content.download_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.download_label }}
                    />
                  </TitleSections>
                }
              </DivSection>
            </DivSections>
          }
          {(pathName === '/medico' && medicContent && medicContent.content) &&
            <DivSections>
              <DivSection>
                {medicContent.content.home_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.home_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: patientContent.content.home_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.benefits_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.benefits_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.benefits_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.fidelity_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.fidelity_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.fidelity_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.payments_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.payments_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.payments_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.billing_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.billing_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.billing_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.iosandroid_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.iosandroid_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.iosandroid_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.clients_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.clients_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.clients_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.files_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.files_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.files_label }}
                    />
                  </TitleSections>
                }
                {medicContent.content.notifications_label !== '' &&
                  <TitleSections
                    activeClass='active'
                    to={medicContent.content.notifications_label}
                    spy={true}
                    smooth={true}
                    duration={500}
                    delay={100}
                    offset={-70}
                    onClick={() => setIsOpen(false)}
                  >
                    <Text16
                      color='#9AA1AC'
                      dangerouslySetInnerHTML={{ __html: medicContent.content.notifications_label }}
                    />
                  </TitleSections>
                }
              </DivSection>
            </DivSections>
          }
          {( pathName === '/paciente' || pathName === '/aderir') &&
            <DivButtonChangeUrl onClick={() => setIsOpen(false)}>
              <Route to="/medico">
                <ButtonChangeUrl>
                  <Text14
                    color='#fff'
                    dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.header_button_text }}
                  />
                </ButtonChangeUrl>
              </Route>
            </DivButtonChangeUrl>
          }
          {pathName === '/medico' &&
            <DivButtonChangeUrl onClick={() => setIsOpen(false)}>
              <Route to="/paciente">
                <ButtonChangeUrl>
                  <Text14
                    color='#fff'
                    dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.header_button_text }}
                  />
                </ButtonChangeUrl>
              </Route>
            </DivButtonChangeUrl>
          }
        </DivMenuModal>
      </ModalMenu>
    </DivNav>
  )
}

const DivNav = styled.div`
  display:flex;
  flex-flow: row;
  background: #FFFFFF;
  height: 80px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
`;
const ButtonModal = styled.div`
  display: none;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: #77EDA6;
  border-radius: 6px;
  padding: 12px;
  margin-right: 26px;
  transition: 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
  @media screen and (max-width: 1024px){
    display: flex;
  }
`;
const Icon = styled.img`
  height: 16px;
  width: 16px;
  max-height: 16px;
  max-width: 16px;
  object-fit: contain;
`;
const DivRowMenu = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 1024px){
    display: none;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  ${({ padding }) => padding && css`
    padding: 0 24px; 
  `}
  ${({ center }) => center && css`
    align-items:center;
  `}
`;
const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width:100%;
  align-items: center;
  ${({ padding }) => padding && css`
    padding: 8px 24px; 
  `}
`;
const Logo = styled.img`
  height: 40px;
  max-height: 40px;
  margin-left: 26px;
  object-fit: contain;
`;
const DivSections = styled.div`
  display:flex;
  flex-flow:row;
  height: 100%;
  @media screen and (max-width: 1024px){
    flex-flow: column;
  }
`;
const DivSection = styled.div`
  height: 100%;
  display:flex;
  align-items:center;
  @media screen and (max-width: 1024px){
    flex-flow: column;
    align-items:flex-start;
  }
`;
const TitleSections = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  box-sizing: border-box;
  border-bottom: 4px solid transparent;
  transition: all 0.4s ease-in-out;
  margin-right: 24px;
  div{
    transition: all 0.4s ease-in-out;
  }
  :hover{
    cursor:pointer;
  }
  &.active{
    div{
      font-family: 'SFProTextBold' ,sans-serif;
      color:#3B3F45;
    }
    border-bottom: 4px solid #77EDA6;
  }
  @media screen and (max-width: 1024px){
    margin-bottom: 16px;
    margin-right:0;
  }
`;
const DivToggleLanguage = styled.div`
  min-width: 80px;
  max-width: 80px;
  height: 100%;
  border-left:1px solid #E0E0E0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  @media screen and (min-width:1025px){
    display: flex;
  }
  @media screen and (max-width:1024px){
    display: none;
  }
`;
const Dropdown = styled(Select)`
  height:80px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333;
  border-radius: 0;
  text-transform: uppercase;
  .dropdown__control {
    background-color: transparent;
    height: 100%;
    border: unset;
    padding: 0 16px;
    :hover {
      border-color: inherit !important;
    }
  }
  .dropdown__control--is-focused{
    outline: none;
    border: unset;
    box-shadow: unset;
    background: #F2F2F2;
    .dropdown__indicator {
      transform: rotate(-180deg);
      color: #2F80ED;
    }
  }
  .dropdown__value-container{
    padding: 0;
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #828282;
    transition: all 0.4s ease-in-out;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #333;
    margin: 0;
    max-width: 100%;
  }

  .dropdown__menu {
    margin:0;
    border-radius: 0;
    & div {
      .css-1n7v3ny-option {
        background-color: transparent;
      }
    }
  }
  .dropdown__menu-list{
    padding: 0;
  }
  .dropdown__option{
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid #E0E0E0;
    display:flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }
  .dropdown__option:hover{
    background-color: #F2F2F2 !important;
    cursor: pointer;
  }
  .dropdown__option--is-focused{
    background-color: #F2F2F2;
  }
  .dropdown__option--is-selected{
    background-color: #E0E0E0 ;
  }

  ${({ top }) => top && css`
    .dropdown__option{
      width: 100%;
      justify-content: flex-start;
    }
  `}
`;
const DivMenuModal = styled.div`
  display: flex;
  flex-flow:column;
  justify-content: space-between;
  padding: 26px;
`;
const DivItemsModal = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivToggleLanguageModal = styled.div`
  min-width:120px;
  max-width: 100%;
  height: 100%;
  margin-top: 40px;
  border-top:1px solid #E0E0E0;
  border-bottom:1px solid #E0E0E0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DivButtonChangeUrl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
  border-left: 1px solid #EBEEF2;
  @media screen and (max-width: 1024px){
    justify-content: flex-start;
    padding: 0;
    border-left: unset;
  }
`;
const ButtonChangeUrl = styled.div`
  width: 185px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
`;