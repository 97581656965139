import styled, { css } from 'styled-components';

const Text16 = styled.div`
font-family: ${({ font }) => font ? font : 'SFProTextSemibold'} , sans-serif;
  font-size: 16px;
  line-height:${({ height }) => height ? height : 20}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : 'black'};
  column-count:${({ withCols }) => withCols ? 2 : 1};
  font-weight:normal;

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  
  p {
    :first-child {
      margin-bottom:8px;
    }
  }

  @media screen and (max-width: 768px) {
    column-count:1;
  }
`;

export default Text16;