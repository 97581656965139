import React, { useContext, useEffect, useState } from 'react'
import { store } from '../../utils/store';
import styled from 'styled-components';
import { Agenda, BannerMedico, Clientes, Faturacao, Ficheiros, Fidelidade, IosAndroid, Notificacoes, Pagamentos } from '../organisms';
import { Footer, ModalForm } from '../molecules';
import { ParallaxProvider } from 'react-scroll-parallax';
import { H6, Text14 } from '../atoms';
import { Link as Route } from 'react-router-dom';

const Scroll = require('react-scroll');
const scroll = Scroll.animateScroll;

export default function Medico() {

  const { medicContent } = useContext(store);

  useEffect(() => {
    // scroll.scrollToTop();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [consultas, setConsultas] = useState(true);

  return (
    <>
      {medicContent &&
        <ParallaxProvider>
          <ModalForm isOpen={isOpen} toggleModal={() => setIsOpen(false)} medicContent={medicContent} />
          <BannerMedico medicContent={medicContent} />
          <Agenda medicContent={medicContent} />
          <Fidelidade medicContent={medicContent} />
          <Pagamentos medicContent={medicContent} />
          <Faturacao medicContent={medicContent} />
          <IosAndroid medicContent={medicContent} />
          <Clientes medicContent={medicContent} />
          <Ficheiros medicContent={medicContent} />
          <Notificacoes medicContent={medicContent} />
          <Footer medicContent={medicContent} />
          {consultas &&
            <DivFloat>
              <ContainerFloat>
                <H6 color='#fff' dangerouslySetInnerHTML={{ __html: 'Comece já hoje a realizar Vídeo Consultas' }} />
                <Route to="/aderir">
                  <Button>
                    <Text14 color="#008FA6" dangerouslySetInnerHTML={{ __html: "Aderir a YesDoc" }} />
                  </Button>
                </Route>
                <ButtonModal onClick={() => setConsultas(!consultas)}>
                  <Icon src="/static/images/menu_close.svg" />
                </ButtonModal>
              </ContainerFloat>
            </DivFloat>
          }
        </ParallaxProvider>
      }
    </>
  )
}

const DivFloat = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
`;
const ContainerFloat = styled.div`
  background-color: #008FA6;
  width: 100%;
  padding: 8px 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media screen and (max-width:700px){
    padding: 8px 24px 8px 16px;
  }
`;
const Button = styled.a`
  margin-left: 16px;
  width:147px;
  height:40px;
  display:flex;
  flex-flow:row;
  align-items:center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  :hover{
    background-color:rgba(255, 255, 255, 0.8);
    cursor:pointer;
  }
  @media screen and (max-width:700px){
    margin-left: 8px;
    margin-right: 8px
  }
`;
const ButtonModal = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 6px;
  transition: 0.4s ease-in-out;
  z-index: 1;
  :hover{
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 16px;
  width: 16px;
  max-height: 16px;
  max-width: 16px;
  object-fit: contain;
`;