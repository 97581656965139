import React from 'react'
import styled from 'styled-components';
import { Container, Section, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function Ficheiros({ medicContent }) {
  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.files_label} backgroundColor="#fff">
        <Container md>
          <Wrapper both noHeight>
            <Content>
              <DivIntro>
                <Logo src="/static/images/icon_clientes.svg" />
                <Intro
                  smallTitleColor="#77EDA6"
                  smallTitle={medicContent.content && medicContent.content.files_label}
                  titleSize="H4"
                  title={medicContent.content && medicContent.content.files_text1}
                  titleColor="#008FA6"
                  titleMaxWidth="294px"
                  description={medicContent.content && medicContent.content.files_text2}
                  descriptionSize={18}
                  descriptionMaxWidth="356px"
                  descriptionColor="#9AA1AC"
                />
              </DivIntro>
              <Image src={medicContent.content && medicContent.content.files_image} />
            </Content>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;
}

const Content = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  @media screen and (max-width: 900px){
    flex-flow: column;
  }
`;
const Image = styled.img`
  max-height: 600px;
  max-width: 100%;
  object-fit: contain;
  @media screen and (max-width: 900px){
    margin-top: 40px;
  }
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 80px;
  margin-right: 50px;
  @media screen and (max-width: 900px){
    margin-right: 0;
    margin-top: 0;
  }
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;