import React, { useContext } from 'react'
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Container, Section, Text12, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function BannerMedico({ medicContent, toogleForm }) {

  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.home_label} backgroundColor="#F0FFF6" onTop2={true}>
        <DivImage>
          <Image src={medicContent.content && medicContent.content.home_image} />
        </DivImage>
        <Wrapper top full >
          <Container lg>
            <Content>
              <DivIntro>
                <Intro
                  smallTitleColor="#77EDA6"
                  smallTitle={medicContent.content && medicContent.content.home_text1}
                  titleSize="H1"
                  title={medicContent.content && medicContent.content.home_text2}
                  titleColor="#008FA6"
                  titleMaxWidth="666px"
                  description={medicContent.content && medicContent.content.home_text3}
                  descriptionSize={18}
                  descriptionMaxWidth="470px"
                  descriptionColor="#9AA1AC"
                />
                <DivRow>
                  <Button onClick={() => toogleForm()}>
                    <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.home_button_text }} />
                  </Button>
                  <ButtonPage href="https://app.yesdoc.pt/" target="_blank">
                    <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: 'Efetuar Login' }} />
                  </ButtonPage>
                </DivRow>
              </DivIntro>
              {/* <ImagePhone src={medicContent.content && medicContent.content.home_image} /> */}
            </Content>
          </Container>
        </Wrapper>
        <DivFloat>
          <TextFloat color="#008FA6" dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.home_explore_text }} />
          <IconFloat src={medicContent.content && medicContent.content.home__explore_image} />
        </DivFloat>
      </Section>
    )
  }
  return null;
}

const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: calc(100vh - calc(140px + (260 - 140) * ((100vw - 375px) / (1920 - 375))));
  @media screen and (max-width:700px){
    align-items: flex-end;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  @media screen and (max-width:700px){
    margin-bottom: 120px
  }
`;
const DivImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  @media screen and (max-width:700px){
    right: 0;
    top: unset;
  }
`;
const Image = styled.img`
  max-height: 618px;
  @media screen and (max-width: 1600px){
    max-height: calc(300px + (618 - 300) * ((100vw - 375px) / (1600 - 375)));
  }
`;
const ImagePhone = styled.img`
  display: none;
  @media screen and (max-width:700px){
    margin-top: 80px;
    margin-left: auto;
    margin-right: -15px;
    display: flex;
    max-width: calc(250px + (532 - 250) * ((100vw - 375px) / (1920 - 375)));
  }
`;
const ContainerDownload = styled.div`
  display:flex;
  margin-top:60px;
  flex-flow:column;
`;
const DivButtons = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:8px;
`;
const Button = styled.div`
  margin-top: 24px;
  width: 176px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
`;
const ButtonPage = styled.a`
  margin-top: 24px;
  width: 176px;
  height: 40px;
  background: #008FA6;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
    background: #006373;
  }
`;
const Icon = styled.img`
  height:14px;
  margin-right:8px;
  ${({ footer }) => footer && css`
    width:12px;
    height:12px;
    margin:0;
  `}
`;
const DivFloat = styled.div`
  position: absolute;
  bottom: -28px;
  left: 50%;
  right:50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width:700px){
    display: none;
  }
`;
const TextFloat = styled(Text12)`
  text-transform: uppercase;
  transform: rotate(-90deg);
  line-height: 24px;
  text-align: right;
  margin-bottom: 40px;
`;
const IconFloat = styled.img`
  height: 100px;
  width: 35px;
`;