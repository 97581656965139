import React from 'react'
import styled, { css } from 'styled-components';
import { Container, Section, Text14, Text18, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function Fidelidade({ medicContent, toogleForm }) {

  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.fidelity_label} backgroundColor="#fff">
        <Container md>
          <Wrapper both noHeight>
            <DivRowSpace>
              <DivColumn style={{ marginTop: 40 }}>
                <Logo src="/static/images/icon_fidelidade.svg" />
                <Intro
                  smallTitleColor="#77EDA6"
                  smallTitle={medicContent.content && medicContent.content.fidelity_label}
                  titleSize="H4"
                  title={medicContent.content && medicContent.content.fidelity_text1}
                  titleColor="#008FA6"
                  titleMaxWidth="294px"
                  description={medicContent.content && medicContent.content.fidelity_text2}
                  descriptionSize={18}
                  descriptionMaxWidth="356px"
                  descriptionColor="#9AA1AC"
                />
                <Button onClick={ () => toogleForm()}>
                  <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.fidelity_button_text}}/>
                </Button>
              </DivColumn>
              <Image src={medicContent.content && medicContent.content.fidelity_image} />
            </DivRowSpace>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;
}

const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: calc(45px + (180 - 45) * ((100vw - 375px) / (1920 - 375)));
  @media screen and (max-width: 1000px){
    flex-flow: column;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;
const Button = styled.a`
  margin-top: 24px;
  width: 176px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
`;
const Image = styled.img`
  width: 758px;
  max-width: 100%;
  max-height: 616px;
  object-fit: contain;
  @media screen and (max-width: 1100px){
    width: 458px;
  }
  @media screen and (max-width: 1000px){
    margin-top: 40px;
  }
`;