import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import data from './data';

import {
  Section,
  Container,
  Wrapper,
  H3,
  H5,
  Text12,
  Text10,
} from '../../atoms';

export default function LegalInformation({ legalContent }) {

  const [dataTabs, setDataTabs] = useState([]);

  const [dataLegal, setDataLegal] = useState([]);

  const [active, setActive] = useState(0);

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    if (hash && hash !== '') {
      let index = dataLegal.findIndex((line) => line.sectionName === hash);
      if (index !== -1) {
        setActive(index);
      }
    }
  }, []);

  useEffect(() => {
    if (legalContent) {
      setDataLegal([
        {
          sectionName: 'privacidade',
          title: legalContent && legalContent.content && legalContent.content.privacy_title,
          text: legalContent && legalContent.content && legalContent.content.privacy_content
        },
        {
          sectionName: 'cookies',
          title: legalContent && legalContent.content && legalContent.content.cookies_title,
          text: legalContent && legalContent.content && legalContent.content.cookies_content
        },
        {
          sectionName: 'uso',
          title: legalContent && legalContent.content && legalContent.content.terms_title,
          text: legalContent && legalContent.content && legalContent.content.terms_content
        }
      ]);

      setDataTabs([
        {
          value: 'privacidade',
          label: legalContent && legalContent.content && legalContent.content.privacy_title
        },
        {
          value: 'cookies',
          label: legalContent && legalContent.content && legalContent.content.cookies_title
        },
        {
          value: 'uso',
          label: legalContent && legalContent.content && legalContent.content.terms_title
        }
      ]);
    }

  }, [legalContent]);

  if (legalContent) {
    return (
      <Section noFade>
        <Banner>
          <Container lg>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <H3
                color='#008FA6'
                dangerouslySetInnerHTML={{ __html: legalContent && legalContent.content && legalContent.content.page_title }}
              />
            </div>
          </Container>
        </Banner>
        <ContainerLegal>
          {(dataLegal && dataLegal.length > 0) &&
            <TabsContainer className='desktop'>
              <Tabs selectedIndex={active} onSelect={(index) => setActive(index)}>
                <WrapperTab>
                  <Container lg>
                    <TabInnerPannel>
                      <TabList>
                        {dataTabs.map((value, index) => (
                          <AllTabs key={String(index)}>
                            <Text10>
                              {value.label}
                            </Text10>
                          </AllTabs>
                        ))}
                      </TabList>
                    </TabInnerPannel>
                  </Container>
                </WrapperTab>
                <Wrapper both noHeight>
                  <Container md>
                    {dataLegal.map((value, index) => (
                      <TabPanel key={String(index)}>
                        <TabInnerPannel>
                          <H5
                            color="#008FA6"
                            dangerouslySetInnerHTML={{ __html: value.title }}
                          />
                          <Margin />
                          <Text
                            style={{ padding: 'inherit', margin: 'inherit' }}
                            dangerouslySetInnerHTML={{ __html: value.text }}
                          />
                        </TabInnerPannel>
                      </TabPanel>
                    ))}
                  </Container>
                </Wrapper>
              </Tabs>
            </TabsContainer>
          }
          {(dataLegal && dataLegal.length > 0) &&
            <TabsContainer className='mobile'>
              <WrapperTab>
                <Container lg>
                  <DropdownContainer>
                    <Dropdown
                      onChange={options => setActive(options.value)}
                      defaultValue={dataTabs[0]}
                      value={dataTabs[active]}
                      classNamePrefix='dropdown'
                      options={dataTabs}
                    />
                  </DropdownContainer>
                </Container>
              </WrapperTab>
              <Wrapper both noHeight>
                <Container md>
                  {dataLegal.map((value, index) => (
                    <TabInnerPannel
                      key={String(index)}
                      className={(value.sectionName === active || index === active) ? 'show' : 'hide'}
                    >
                      <H5
                        color="#008FA6"
                        dangerouslySetInnerHTML={{ __html: value.title }}
                      />
                      <Margin />
                      <Text
                        style={{ padding: 'inherit', margin: 'inherit' }}
                        dangerouslySetInnerHTML={{ __html: value.text }}
                      />
                    </TabInnerPannel>
                  ))}
                </Container>
              </Wrapper>
            </TabsContainer>
          }
        </ContainerLegal>
      </Section>
    )
  }
  return null
}

const Banner = styled.div`
  height:50vh;
  background-color:#F0FFF6;
  display: flex;
  align-items:center;
`;
const ContainerLegal = styled.div`
  position:relative;
  height: 100%;
`;
const TabsContainer = styled.div`
  @media screen and (min-width:768px){
    &.desktop{
      display:block;
    }
    &.mobile{
      display:none;
    }
  }
  @media screen and (max-width:767px){
    &.desktop{
      display:none;
    }
    &.mobile{
      display:block;
    }
  }
`;
const WrapperTab = styled.div`
  position:relative;
  background-color:#F0FFF6;
`;
const TabInnerPannel = styled.div`
  &.show {
    display:block;
  }
  &.hide {
    display:none;
  }
`;
const AllTabs = styled(Tab)`
  display:inline-flex;
  margin-right:31px;
  margin-top:25px;
  margin-bottom: 25px;
  cursor:pointer;
  >div{
    transition: all 0.5s ease;
    color: #9AA1AC;
  }
  &:hover,
  &.react-tabs__tab--selected {
    >div{
      color: #008FA6;
    }
  }
`;
const Margin = styled.div`
  margin-bottom: 8px;
  ${({ medium }) => medium && css`
    margin-bottom: 16px;
  `};
`;
const DropdownContainer = styled.div`
  flex:100%;
  display:flex;
  flex-direction:column;
`;
const Dropdown = styled(Select)`
  flex: 1;
  z-index:2 !important;
  font-size: calc(10px + (12 - 10) * ((100vw - 540px) / (1920 - 540)));
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
  border-radius: 0;
  margin-bottom: 25px;
  .dropdown__control {
    border-radius: 27px;
    background-color: transparent;
    padding: 0 calc(14px + (29 - 14) * ((100vw - 540px) / (1920 - 540)));
    height: 100%;
    border: 1px solid #008FA6;
    box-shadow: 0 0 0 0px #000;

    :hover {
      border-color: inherit !important;
    }
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #008FA6;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: calc(10px + (12 - 10) * ((100vw - 540px) / (1920 - 540)));
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #008FA6;
  }

  .dropdown__menu {
    & div {
      .css-1n7v3ny-option {
        background-color: transparent;
      }
    }
  }
`;
const DivText = styled.div`
  display:flex;
  flex-flow:row;
  @media screen and (max-width: 800px){
    flex-flow:column;
  }
`;
const Image = styled.img`
  width:80px;
  height:80px;
  object-fit:contain;
  margin-right:8px;
  @media screen and (max-width: 800px){
    margin-right:0;
    margin-bottom:8px;
  }
`;
const Text = styled(Text12)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li{
    display: list-item;
  }
`;