import React, { useContext } from 'react'
import styled from 'styled-components';
import { Intro } from '../molecules';
import { Link } from 'react-router-dom';
import { Text12, Text14 } from '../atoms';
import { store } from '../../utils/store';

export default function Home() {

  const { entryContent } = useContext(store);

  return (
    <>
      {entryContent &&
        <Container>
          <ItemLeft>
            <DivZIndex>
              <Logo src={entryContent.content && entryContent.content.logo} />
              <Margin margin={4} />
              <Intro
                centerContainer
                smallTitleColor="#77EDA6"
                smallTitle={entryContent.content && entryContent.content.patient_text1}
                titleSize="H4"
                title={entryContent.content && entryContent.content.patient_text2}
                titleLineHeight={38}
                titleColor="#008FA6"
                titleMaxWidth="412px"
                titleTextAlign="center"
                description={entryContent.content && entryContent.content.patient_text3}
                descriptionSize={18}
                descriptionMaxWidth="470px"
                descriptionColor="#9AA1AC"
                descriptionTextAlign="center"
              />
              <Margin margin={3} />
              <Link to='/paciente'>
                <Button>
                  <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: entryContent.content && entryContent.content.patient_buttonText }} />
                </Button>
              </Link>
            </DivZIndex>
            <ImgPhone src={entryContent.content && entryContent.content.patient_image} />
            <DivToBottom>
              <img src='/static/images/seta.svg' />
              <Text12 color="#008FA6" dangerouslySetInnerHTML={{ __html: entryContent.content && entryContent.content.medic_text1 }} />
            </DivToBottom>
          </ItemLeft>
          <ItemRight>
            <DivZIndex>
              <Logo src={entryContent.content && entryContent.content.logo} />
              <Margin margin={4} />
              <Intro
                centerContainer
                smallTitleColor="#77EDA6"
                smallTitle={entryContent.content && entryContent.content.medic_text1}
                titleSize="H4"
                title={entryContent.content && entryContent.content.medic_text2}
                titleLineHeight={38}
                titleColor="#008FA6"
                titleMaxWidth="412px"
                titleTextAlign="center"
                description={entryContent.content && entryContent.content.medic_text3}
                descriptionSize={18}
                descriptionMaxWidth="470px"
                descriptionColor="#9AA1AC"
                descriptionTextAlign="center"
              />
              <Margin margin={3} />
              <Link to='/medico'>
                <Button>
                  <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: entryContent.content && entryContent.content.medic_buttonText }} />
                </Button>
              </Link>
            </DivZIndex>
            <ImgLaptop src={entryContent.content && entryContent.content.medic_image} />
            <DivToTop>
              <img src='/static/images/seta.svg' />
              <Text12 color="#008FA6" dangerouslySetInnerHTML={{ __html: entryContent.content && entryContent.content.patient_text1 }} />
            </DivToTop>
          </ItemRight>
        </Container>
      }
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const Container = styled.div`
  display: flex;
  flex-flow: row;
  height: 100vh;
  @media screen and (max-width: 900px) {
    flex-flow: column;
    height: 100%;
  }
`;
const ItemLeft = styled.div`
  overflow: hidden;
  position: relative;
  background: #F0FFF6;
  flex:1;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  padding-top: calc(40px + (60 - 40) * ((100vw - 375px) / (1920 - 375)));
  box-sizing: border-box;
  @media screen and (max-width: 900px) {
    min-height: 100vh;
    max-height: 100vh;
    flex: unset;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
`;
const ItemRight = styled.div`
  overflow: hidden;
  position: relative;
  flex:1;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  padding-top: calc(40px + (60 - 40) * ((100vw - 375px) / (1920 - 375)));
  box-sizing: border-box;
  @media screen and (max-width: 900px) {
    min-height: 100vh;
    max-height: 100vh;
    flex: unset;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
  }
`;
const DivZIndex = styled.div`
  display:flex;
  flex-flow: column;
  z-index:1;
  align-items: center;
  @media screen and (max-width: 600px){
    margin-top: 50px;
  }
`;
const Logo = styled.img`    
  height: 40px;
  max-height: 40px;
  object-fit: contain;
`;
const Button = styled.div`
  width: 176px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    background: #008FA6;
  }
`;
const ImgPhone = styled.img`
  position: absolute;
  bottom: calc(-192px + (0 - (-192)) * ((100vw - 375px) / (1920 - 375)));
  max-width: 325px;
  @media screen and (min-width: 1920px) {
    bottom: 0;
  }
`;
const ImgLaptop = styled.img`
  position: absolute;
  bottom: calc(-82px + (0 - (-82)) * ((100vw - 375px) / (1920 - 375)));
  max-width: calc(325px + (657 - 325) * ((100vw - 375px) / (1920 - 375)));
  @media screen and (min-width: 1920px) {
    bottom: 0;
  }
`;
const DivToBottom = styled.div`
  transform: translateX(4%) translateY(-200%) rotate(-90deg);
  transform-origin: center;
  display: none;
  position: absolute;
  right: 20px;
  bottom: 12px;
  margin-bottom: 50px;
  img{
    transform: rotate(180deg);
    margin-right: 20px;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: bounce;

    @keyframes bounce { 
      0%, 100% {transform: translateX(4%) rotate(180deg);}
      50% {transform: translateX(24%) rotate(180deg);}
    } 
  }
  @media screen and (max-width: 900px) {
    display: inline-flex;
  }
  @media screen and (max-width: 500px) {
    transform: translateX(40%) translateY(-200%) rotate(-90deg);
  }
`;
const DivToTop = styled.div`
  transform: translateX(-2%) translateY(300%) rotate(-270deg);
  transform-origin: center;
  display: none;
  position: absolute;
  right: 20px;
  top: 12px;
  margin-top: 30px;
  img{
    margin-left: 20px;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: bounceTop;

    @keyframes bounceTop { 
      0%, 100% {transform: translateX(-100%) rotate(180deg);}
      50% {transform: translateX(-80%) rotate(180deg);}
    } 
  }
  @media screen and (max-width: 900px) {
    display: inline-flex;
  }
  @media screen and (max-width: 500px) {
    transform: translateX(39%) translateY(250%) rotate(-270deg);
  }
`;