import React, { useContext } from 'react'
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Container, Section, Text12, Text14, Text18 } from '../../atoms';
import { Intro } from '../../molecules';


export default function Benefits({ patientContent }) {

  if (patientContent) {
    return (
      <Section className={patientContent.content && patientContent.content.benefits_label} backgroundColor="#fff">
        <Wrapper>
          <Container lg>
            <DivIntro>
              <Intro
                centerContainer
                smallTitleColor="#77EDA6"
                smallTitle={patientContent.content && patientContent.content.benefits_label}
                titleSize="H4"
                title={patientContent.content && patientContent.content.benefits_text1}
                titleColor="#008FA6"
                titleMaxWidth="412px"
                titleTextAlign="center"
                description={patientContent.content && patientContent.content.benefits_text2}
                descriptionSize={18}
                descriptionMaxWidth="470px"
                descriptionColor="#9AA1AC"
                descriptionTextAlign="center"
              />
            </DivIntro>
            <DivBenefits>
              <Item>
                <DivImage>
                  <img src={patientContent.content && patientContent.content.benefits_block1_image} />
                </DivImage>
                <Text18 align="center" style={{ marginBottom: 4 }} color="#008FA6" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block1_text1 }} />
                <Text12 align="center" color="#9AA1AC" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block1_text2 }} />
              </Item>
              <Item>
                <DivImage>
                  <img src={patientContent.content && patientContent.content.benefits_block2_image} />
                </DivImage>
                <Text18 align="center" style={{ marginBottom: 4 }} color="#008FA6" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block2_text1 }} />
                <Text12 align="center" color="#9AA1AC" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block2_text2 }} />
              </Item>
              <Item>
                <DivImage>
                  <img src={patientContent.content && patientContent.content.benefits_block3_image} />
                </DivImage>
                <Text18 align="center" style={{ marginBottom: 4 }} color="#008FA6" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block3_text1 }} />
                <Text12 align="center" color="#9AA1AC" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.benefits_block3_text2 }} />
              </Item>
            </DivBenefits>
          </Container>
        </Wrapper>
      </Section>
    )
  }
  return null;
}

const Wrapper = styled.div`
  padding-top: calc(70px + (130 - 70) * ((100vw - 375px) / (1920 - 375)));
  padding-bottom: calc(70px + (130 - 70) * ((100vw - 375px) / (1920 - 375)));
  height: 100%;
`;
const DivIntro = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const DivBenefits = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: -20px;
  @media screen and (max-width:1200px){
    flex-flow: column;
    align-items: center;
    margin-right: 0px;
  }
`;
const Item = styled.div`
  width: 372px;
  height: 472px;
  background: #FFFFFF;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 52px 60px;
  box-sizing: border-box;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: calc(100% - 30px);
  :last-child{
    margin:0;
  }
  @media screen and (max-width:1200px){
    margin-right: 0;
  }
`;
const DivImage = styled.div`
  width: 100%;
  height: 250px;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content:center;
  margin-bottom: 32px;
  img{
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`;