import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './utils/client';
import { Main } from './components/layout';
import { BrowserRouter } from "react-router-dom";
import { store } from './utils/store';
import moment from 'moment';
import { I18ZenProvider } from '@keegpt/i18zen';


import en from './locales/en.json';
import pt from './locales/pt.json';

function App() {
  const { lang } = useContext(store);

  moment.locale(lang);

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <I18ZenProvider
          locales={{ pt, en }}
          errorCallback={console.error}
          initialLocale={lang}
          fallbackLocale={lang}
        >
          <Main />
        </I18ZenProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
