import React from 'react'
import styled, { css } from 'styled-components';
import { Container, Section, Text14, Text18, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function Attendance({ patientContent }) {

  if (patientContent) {
    return (
      <Section className={patientContent.content && patientContent.content.service_label} backgroundColor="#fff">
        <Container lg>
          <Wrapper both noHeight>
            <DivRowSpace>
              <DivRow>
                <NumberBadge>
                  <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: '6' }} />
                </NumberBadge>
                <DivColumn>
                  <Logo src="/static/images/icon_atendimento.svg" />
                  <Intro
                    smallTitleColor="#77EDA6"
                    smallTitle={patientContent.content && patientContent.content.service_label}
                    titleSize="H4"
                    title={patientContent.content && patientContent.content.service_text1}
                    titleColor="#008FA6"
                    titleMaxWidth="352px"
                    description={patientContent.content && patientContent.content.service_text2}
                    descriptionSize={18}
                    descriptionMaxWidth="392px"
                    descriptionColor="#9AA1AC"
                  />
                  <ContainerDownload>
                    <Text14 color="#3B3F45" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.home_text4 }} />
                    <DivButtons>
                      <Button href={patientContent.content && patientContent.content.download_apple_store_url} target='_blank'>
                        <Icon src="/static/images/logo_apple.svg" />
                        <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "App Store" }} />
                      </Button>
                      <Button href={patientContent.content && patientContent.content.download_google_play_url} target='_blank'>
                        <Icon src="/static/images/logo_playstore.svg" />
                        <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "Google Play" }} />
                      </Button>
                    </DivButtons>
                  </ContainerDownload>
                </DivColumn>
              </DivRow>
              <Image src={patientContent.content && patientContent.content.service_image} />
            </DivRowSpace>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;
}

const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  @media screen and (max-width: 1000px){
    flex-flow: column;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const NumberBadge = styled.div`
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  background: #77EDA6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-right: 44px;
  border-radius: 50%;
  overflow: hidden;
  @media screen and (max-width: 600px){
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
`;
const Logo = styled.img`
  max-width: 40px;
  max-height: 40px;
  height: 40px;
  object-fit: contain;
  margin-bottom: 16px;
`;
const ContainerDownload = styled.div`
  display:flex;
  margin-top:60px;
  flex-flow:column;
  @media screen and (max-width: 1000px){
    margin-bottom: 60px;
  }
`;
const DivButtons = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:8px;
`;
const Button = styled.a`
  width:147px;
  height:40px;
  display:flex;
  flex-flow:row;
  align-items:center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  :first-child{
    margin-right:12px;
  }
  :hover{
    background-color:#E7EBF2;
    cursor:pointer;
  }
`;
const Icon = styled.img`
  height:14px;
  margin-right:8px;
  ${({ footer }) => footer && css`
    width:12px;
    height:12px;
    margin:0;
  `}
`;
const Image = styled.img`
  width: 527px;
  max-width: 100%;
  max-height: 616px;
  object-fit: contain;
`;