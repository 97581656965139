import { useI18Zen } from '@keegpt/i18zen';
import axios from "axios";
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { Container, H5, Modal, Section, Text12, Text16, Wrapper } from '../atoms';
import { Intro } from '../molecules';const Scroll = require('react-scroll');
const scroll = Scroll.animateScroll;

export default function Aderir() {

  const { __ } = useI18Zen();

  const [options, setOptions] = useState([]);

  const checkOptions = (data) => {
    if (data && data.length > 0) {
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        temp.push({
          value: data[i].id,
          label: data[i].name
        })
      }
      setOptions(temp);
    } else {
      setOptions([])
    }
  }

  useEffect(() => {
    scroll.scrollToTop();
    axios
      .get("https://app.yesdoc.pt:8888/specialty")
      .then(response => {
        if (response.data) {
          checkOptions(response.data);
        }
      })
      .catch(error => {
        checkOptions([]);
      });
  }, []);

  const [inputs, setInputs] = useState({
    name: '',
    photo: '',
    email: '',
    address: '',
    specialty: '',
    clinic: '',
    order: '',
    university: '',
    year: '',
    copyCC: '',
    copyOrder: '',
    copyInsurance: '',
    iban: '',
    cc: '',
    nif: ''
  })

  const handleInput = (event) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
  };

  const hiddenPhotoInput = useRef(null);
  const hiddenCCInput = useRef(null);
  const hiddenOrdemInput = useRef(null);
  const hiddenSeguroInput = useRef(null);

  const handleUpload = (input) => {
    if (input && input === "photo") {
      hiddenPhotoInput.current.click();
    } else if (input && input === "copyCC") {
      hiddenCCInput.current.click();
    } else if (input && input === "copyOrder") {
      hiddenOrdemInput.current.click();
    } else if (input && input === "copyInsurance") {
      hiddenSeguroInput.current.click();
    }
  };

  const handleChange = (event, input) => {
    const fileUploaded = event.target.files;
    if (input && input === "photo") {
      setInputs(inputs => ({ ...inputs, photo: fileUploaded[0] }));
    } else if (input && input === "copyCC") {
      setInputs(inputs => ({ ...inputs, copyCC: fileUploaded[0] }));
    } else if (input && input === "copyOrder") {
      setInputs(inputs => ({ ...inputs, copyOrder: fileUploaded[0] }));
    } else if (input && input === "copyInsurance") {
      setInputs(inputs => ({ ...inputs, copyInsurance: fileUploaded[0] }));
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sucess, setSucess] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
    const form = new FormData();
    form.append("medical_order_card_photo", inputs.copyOrder);
    form.append("insurance_policy_photo", inputs.copyInsurance);
    form.append("identity_card_photo", inputs.copyCC);
    form.append("avatar", inputs.photo);
    form.append("name", inputs.name);
    form.append("ballot_number", inputs.order);
    form.append("identity_card", inputs.cc);
    form.append("nif", inputs.nif);
    form.append("adress", inputs.address);
    form.append("specialties_id", inputs.specialty);
    form.append("email", inputs.email);
    form.append("clinical_name", inputs.clinic);
    form.append("university", inputs.university);
    form.append("conclusion_year", inputs.year);
    form.append("iban", inputs.iban);
    form.append("appId", "8E2B185D2EA17D1BDE9BAE4A619FD7A1");

    const config = {
      method: 'POST',
      url: 'https://app.yesdoc.pt:8888/doctor', headers: {
        'content-type': 'multipart/form-data',
        accept: 'application/json'
      },
      data: form
    }

    setLoading(true);
    axios.request(config)
      .then(response => {
        setSucess(true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        error?.response?.data?.message && setError(error?.response?.data?.message);
      })
  }

  useEffect(() => {
    if (sucess === true) {
      setTimeout(() => {
        setInputs({
          name: '',
          photo: '',
          email: '',
          address: '',
          specialty: '',
          clinic: '',
          order: '',
          university: '',
          year: '',
          copyCC: '',
          copyOrder: '',
          copyInsurance: '',
          iban: '',
          cc: '',
          nif: ''
        });
        setSucess(false);
      }, 2000);
    }
  }, [sucess])

  useEffect(() => {
    if (error !== null) {
      setTimeout(() => {
        setError(null)
      }, 2000);
    }
  }, [error]);

  return (
    <Section>
      <Container lg>
        <Wrapper full both>
          <Margin margin={5}/>
          {(loading === false && sucess === true) &&
            <DivTextInfoRegistration>
              <Correct src="/static/images/form_correct.png" />
              <H5 color="#008FA6" dangerouslySetInnerHTML={{ __html: __("sucessForm") }} />
            </DivTextInfoRegistration>
          }
          {(sucess === false) &&
            <DivContent>
              <DivLeft>
                <Image src="/static/images/imagem_registo.svg" />
                <Intro
                  titleSize="H4"
                  title={__('titulo')}
                  titleColor="#008FA6"
                  titleMaxWidth="330px"
                  description={__('descricao')}
                  descriptionSize={12}
                  descriptionMaxWidth="237px"
                  descriptionColor="#9AA1AC"
                  descriptionLineHeight={14}
                />
              </DivLeft>
              <DivRight>
                {loading === true &&
                  <Loading isLoading={loading}>
                    <Align>
                      <div className="sk-circle">
                        <div className="sk-circle1 sk-child"></div>
                        <div className="sk-circle2 sk-child"></div>
                        <div className="sk-circle3 sk-child"></div>
                        <div className="sk-circle4 sk-child"></div>
                        <div className="sk-circle5 sk-child"></div>
                        <div className="sk-circle6 sk-child"></div>
                        <div className="sk-circle7 sk-child"></div>
                        <div className="sk-circle8 sk-child"></div>
                        <div className="sk-circle9 sk-child"></div>
                        <div className="sk-circle10 sk-child"></div>
                        <div className="sk-circle11 sk-child"></div>
                        <div className="sk-circle12 sk-child"></div>
                      </div>
                    </Align>
                  </Loading>
                }
                {loading === false &&
                  <>
                    {error !== null &&
                      <DivColumnError>
                        <Text16 color="#fff" dangerouslySetInnerHTML={{ __html: error }} />
                      </DivColumnError>
                    }
                    <Form onSubmit={(event) => submitForm(event)}>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nomeLabel") }} />
                          <Input type="text" width={448} placeholder={__("nomePlaceholder")} value={inputs.name} name="name" onChange={handleInput} required />
                        </DivColumn>
                        <DivColumn>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("fotoLabel") }} />
                          <DivInput>
                            <Input type="text" value={inputs.photo && inputs.photo.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("fotoPlaceholder")} required />
                            <input
                              type="file"
                              ref={hiddenPhotoInput}
                              onChange={(event) => handleChange(event, 'photo')}
                              style={{ width: 0, height: 0 }}
                            />
                            <IconInput onClick={() => handleUpload('photo')}>
                              <img src="/static/images/icon_download.svg" />
                            </IconInput>
                          </DivInput>
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("emailLabel") }} />
                          <Input type="email" placeholder={__("emailPlaceholder")} value={inputs.email} name="email" onChange={handleInput} required />
                        </DivColumn>
                        <DivColumn>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("moradaLabel") }} />
                          <Input type="text" placeholder={__("moradaPlaceholder")} value={inputs.address} name="address" onChange={handleInput} required />
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("ccNumLabel") }} />
                          <Input type="text" placeholder={__("ccNumPlaceholder")} value={inputs.cc} name="cc" onChange={handleInput} required />
                        </DivColumn>
                        <DivColumn >
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nifNumLabel") }} />
                          <Input type="text" placeholder={__("nifNumPlaceholder")} value={inputs.nif} name="nif" onChange={handleInput} required />
                        </DivColumn>
                      </DivRow>
                      {(options && options.length > 0) &&
                        <DivRow>
                          <DivColumn>
                            <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("especialidadeLabel") }} />
                            <Dropdown
                              onChange={options => setInputs(inputs => ({ ...inputs, specialty: options.value }))}
                              placeholder={__("especialidadePlaceholder")}
                              classNamePrefix='dropdown'
                              options={options}
                            />
                          </DivColumn>
                        </DivRow>
                      }
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nomeCliniLabel") }} />
                          <Input type="text" placeholder={__("nomeCliniPlaceholder")} value={inputs.clinic} name="clinic" onChange={handleInput} required />
                        </DivColumn>
                        <DivColumn >
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("nIscricaoLabel") }} />
                          <Input type="text" width={252} placeholder={__("nIscricaoPlaceholder")} value={inputs.order} name="order" onChange={handleInput} required />
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("univLabel") }} />
                          <Input type="text" placeholder={__("univPlaceholder")} value={inputs.university} name="university" onChange={handleInput} required />
                        </DivColumn>
                        <DivColumn >
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("anoLabel") }} />
                          <Input type="number" width={118} placeholder={__("anoPlaceholder")} value={inputs.year} name="year" min="1" max="9999" onChange={handleInput} required />
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("ccLabel") }} />
                          <DivInput>
                            <Input type="text" value={inputs.copyCC && inputs.copyCC.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("ccPlaceholder")} required />
                            <input
                              type="file"
                              ref={hiddenCCInput}
                              onChange={(event) => handleChange(event, 'copyCC')}
                              style={{ width: 0, height: 0 }}
                            />
                            <IconInput onClick={() => handleUpload('copyCC')}>
                              <img src="/static/images/icon_download.svg" />
                            </IconInput>
                          </DivInput>
                        </DivColumn>

                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("cOrdemLabel") }} />
                          <DivInput>
                            <Input type="text" value={inputs.copyOrder && inputs.copyOrder.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("cOrdemPlaceholder")} required />
                            <input
                              type="file"
                              ref={hiddenOrdemInput}
                              onChange={(event) => handleChange(event, 'copyOrder')}
                              style={{ width: 0, height: 0 }}
                            />
                            <IconInput onClick={() => handleUpload('copyOrder')}>
                              <img src="/static/images/icon_download.svg" />
                            </IconInput>
                          </DivInput>
                        </DivColumn>
                        <DivColumn>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("apoliceLabel") }} />
                          <DivInput>
                            <Input type="text" value={inputs.copyInsurance && inputs.copyInsurance.name} width={236} style={{ pointerEvents: 'none' }} placeholder={__("apolicePlaceholder")} required />
                            <input
                              type="file"
                              ref={hiddenSeguroInput}
                              onChange={(event) => handleChange(event, 'copyInsurance')}
                              style={{ width: 0, height: 0 }}
                            />
                            <IconInput onClick={() => handleUpload('copyInsurance')}>
                              <img src="/static/images/icon_download.svg" />
                            </IconInput>
                          </DivInput>
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn margin={24}>
                          <Text12 color="#3B3F45" height={24} dangerouslySetInnerHTML={{ __html: __("ibanLabel") }} />
                          <Input type="text" placeholder={__("ibanPlaceholder")} minLength={25} maxLength={25} value={inputs.iban} name="iban" onChange={handleInput} required />
                        </DivColumn>
                      </DivRow>
                      <DivRow>
                        <DivColumn>
                          <Text12 color="#9AA1AC" style={{ marginBottom: 4 }} height={20} dangerouslySetInnerHTML={{ __html: __("obrigatorios") }} />
                          <Text12 color="#9AA1AC" height={20} dangerouslySetInnerHTML={{ __html: __("roupa") }} />
                        </DivColumn>
                      </DivRow>
                      <ButtonSubmit type="submit" value={__('buttonText')} />
                    </Form>
                  </>
                }
              </DivRight>
            </DivContent>
          }
        </Wrapper>
      </Container>
    </Section>
  )
}
const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`
const Logo = styled.img`
  position: absolute;
  top:24px;
  left:24px;
  max-height: 32px;
  object-fit: contain;
`;
const DivContent = styled.div`
  display:flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  @media screen and (max-width: 1200px){
    flex-flow: column;
  }
`;
const DivLeft = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 64px;
  @media screen and (max-width: 1200px){
    margin-right: 0;
    margin-bottom: 64px;
  }
`;
const DivRight = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const Align = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const Loading = styled.section`
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.4s ease-in-out;

  .sk-circle {
    width: 80px;
    height: 80px;
    position: relative;
    margin-bottom: 80px;
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #B4FFD2;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg); }
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg); }
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg); }
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg); }
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg); }
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg); }
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg); }
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); }
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; }
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; }
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s; }
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s; }
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s; }

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
`;
const DivTextInfoRegistration = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const Correct = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
  margin-bottom: 32px;
`;
const Image = styled.img`
  max-width: 212px;
  margin-bottom: 30px;
`;
const Form = styled.form`
  display: flex;
  flex-flow: column;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: ${({ margin }) => margin ? margin : 0}px;
  @media screen and (max-width: 600px){
    margin: 0;
    margin-bottom: 8px;
  }
`;
const DivColumnError = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 16px;
  width: 330px;
  max-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: red;
  border-radius: 6px;
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 16px;
  :last-of-type{
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const Input = styled.input`
  margin-top: 8px;
  width: ${({ width }) => width ? width : '330'}px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 12px;
  background: #F2F6FA;
  border: 1px solid #EBEEF2;
  font-family: 'SFProTextSemibold',sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  transition: all 0.4s ease-in-out;
  appearance: none;
  max-width: 100%;
  ${({ half }) => half && css`
    width: 100%;
  `}
  :hover{
    border: 1px solid #77EDA6;
  }
  :focus{
    border: 1px solid #008FA6;
  }
  ::placeholder{
    color: #9AA1AC;
  }
  @media screen and (max-width: 800px){
    max-width: 100%;
    ${({ half }) => half && css`
      width: 100%;
      max-width: 140px;
    `}
  }
  @media screen and (max-width: 600px){
    width: 100%;
  }
`;
const DivInput = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  ${Input}{
    padding-right: 48px;
  }
`;
const IconInput = styled.div`    
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 8px;
  bottom: 0;
  height: calc(100% - 8px);
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    object-fit: contain;
  }
  :hover{
    cursor: pointer;
  }
`;

const Dropdown = styled(Select)`
  margin-top: 8px;
  flex: 1;
  z-index:2 !important;
  font-family: 'SFProTextSemibold', sans-serif;
  font-size: calc(10px + (12 - 10) * ((100vw - 540px) / (1920 - 540)));
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #000;
  border-radius: 0;
  .dropdown__control {
    border-radius: 6px;
    background: #F2F6FA;
    border: 1px solid #EBEEF2;
    padding: 0 12px;
    box-shadow: unset;
    height: 48px;
    width: 330px;
    transition: all 0.4s ease-in-out;
    :hover {
      border: 1px solid #77EDA6;
    }
    :focus{
      border: 1px solid #008FA6;
    }
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #828282;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-family: 'SFProTextSemibold', sans-serif;
    font-size: calc(10px + (12 - 10) * ((100vw - 540px) / (1920 - 540)));
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #828282;
  }

  .dropdown__menu {
    & div {
      .css-1n7v3ny-option {
        background-color: #EBEEF2;
      }
    }
    & .dropdown__option--is-selected{
        background-color: #77EDA6;
    }
  }

  ${({ error }) => error && css`
    .dropdown__control {
      border: 1px solid red;
    }
  `}
`;
const ButtonSubmit = styled.input`
  margin-top: 20px;
  width: 212px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  font-family: 'SFProTextSemibold',sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  border: none;
  appearance: none;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    background: #008FA6;
  }
`;