import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { H5, Text12, Text14 } from '../../atoms';

export default function Footer({ patientContent, medicContent }) {
  return (
    <Container>
      <Logo src={(patientContent && patientContent.content && patientContent.content.footer_logo) || (medicContent && medicContent.content && medicContent.content.footer_logo)} />
      <Margin margin={3} />
      <H5 color="#77EDA6" dangerouslySetInnerHTML={{ __html: (patientContent && patientContent.content && patientContent.content.footer_text1) || (medicContent && medicContent.content && medicContent.content.footer_label) }} />
      <Margin margin={1.5} />
      <Text14 color="#fff" align="center" dangerouslySetInnerHTML={{ __html: (patientContent && patientContent.content && patientContent.content.footer_text2) || (medicContent && medicContent.content && medicContent.content.footer_title) }} />
      <Margin margin={1} />
      <DivLinks>
        <Link to='/legal#privacidade'>
          <Text12 color="#D5DBE2" dangerouslySetInnerHTML={{ __html: (patientContent && patientContent.content && patientContent.content.footer_privacy_policy_text) || (medicContent && medicContent.content && medicContent.content.footer_text1) }} />
        </Link>
        {/* <Link to='/legal#cookies'>
          <Text12 color="#D5DBE2" dangerouslySetInnerHTML={{ __html: (patientContent && patientContent.content && patientContent.content.footer_cookies_text) || (medicContent && medicContent.content && medicContent.content.footer_text2) }} />
        </Link> */}
        <Link to='/legal#uso'>
          <Text12 color="#D5DBE2" dangerouslySetInnerHTML={{ __html: (patientContent && patientContent.content && patientContent.content.footer_terms_of_use_text) || (medicContent && medicContent.content && medicContent.content.footer_text3) }} />
        </Link>
      </DivLinks>
    </Container>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 156px;
  padding-bottom: 84px;
  background: #008FA6;
  @media screen and (max-width:600px){
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const Logo = styled.img`
  width: 145px;
  height: 32px;
  object-fit: contain;
`;
const DivLinks = styled.div`
  display: inline-flex;
  a{
    margin-right: 4px;
    padding-right: 4px;
    border-right: 1px solid #D5DBE2;
    :last-child{
      margin-right: unset;
      padding-right: unset;
      border-right: unset;
    }
  }
`;