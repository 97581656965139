import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Section, Text14, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function IosAndroid({ medicContent }) {
  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.iosandroid_label} backgroundColor="#fff">
        <Container md>
          <Wrapper both noHeight>
            <Content>
              <DivIntro>
                <Logo src="/static/images/icon_iosandroid.svg" />
                <Intro
                  smallTitleColor="#77EDA6"
                  smallTitle={medicContent.content && medicContent.content.iosandroid_label}
                  titleSize="H4"
                  title={medicContent.content && medicContent.content.iosandroid_text1}
                  titleColor="#008FA6"
                  titleMaxWidth="294px"
                  description={medicContent.content && medicContent.content.iosandroid_text2}
                  descriptionSize={18}
                  descriptionMaxWidth="356px"
                  descriptionColor="#9AA1AC"
                />
                <Link to="/paciente">
                  <Button>
                    <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.iosandroid_button_text }} />
                  </Button>
                </Link>
                <ContainerDownload>
                  <Text14 color="#3B3F45" dangerouslySetInnerHTML={{ __html: medicContent.content && medicContent.content.iosandroid_text3 }} />
                  <DivButtons>
                    <ButtonApps src={medicContent.content && medicContent.content.download_apple_store_url} target='_blank'>
                      <Icon src="/static/images/logo_apple.svg" />
                      <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "App Store" }} />
                    </ButtonApps>
                    <ButtonApps src={medicContent.content && medicContent.content.download_google_play_url} target='_blank'>
                      <Icon src="/static/images/logo_playstore.svg" />
                      <Text14 color="#121F33" dangerouslySetInnerHTML={{ __html: "Google Play" }} />
                    </ButtonApps>
                  </DivButtons>
                </ContainerDownload>
              </DivIntro>
              <Image src={medicContent.content && medicContent.content.iosandroid_image} />
            </Content>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;
}

const Content = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  @media screen and (max-width: 900px){
    flex-flow: column;
  }
`;
const Image = styled.img`
  max-height: 640px;
  max-width: 100%;
  object-fit: contain;
  @media screen and (max-width: 900px){
    margin-top: 40px;
  }
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-right: 90px;
  @media screen and (max-width: 900px){
    margin-right: 0px;
  }
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;
const Button = styled.div`
  margin-top: 24px;
  width: 176px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover{
    cursor: pointer;
    background: #008FA6
  }
`;
const ContainerDownload = styled.div`
  display:flex;
  margin-top:60px;
  flex-flow:column;
`;
const DivButtons = styled.div`
  display:flex;
  flex-flow:row;
  margin-top:8px;
`;
const ButtonApps = styled.a`
  width:147px;
  height:40px;
  display:flex;
  flex-flow:row;
  align-items:center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 4px;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  :first-child{
    margin-right:12px;
  }
  :hover{
    background-color:#E7EBF2;
    cursor:pointer;
  }
`;
const Icon = styled.img`
  height:14px;
  margin-right:8px;
`;