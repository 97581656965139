import React, { useContext } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import { store } from '../../utils/store';
import { Footer } from '../molecules';
import { LegalInformation } from '../organisms';

export default function Legal() {

  const { patientContent, legalContent } = useContext(store);
  
  return (
    <ParallaxProvider>
      <LegalInformation legalContent={legalContent} />
      <Footer patientContent={patientContent} />
    </ParallaxProvider>
  )
}
