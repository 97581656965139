import React from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';

export default function Section(props) {

  const {
    className,
    backgroundColor,
    twoColors,
    children,
    onTop,
    margin,
    hiddenX,
    onTop2,
    noFade
  } = props;

  return (
    <DivSection hiddenX={hiddenX} className={className} backgroundColor={backgroundColor} twoColors={twoColors} onTop={onTop} onTop2={onTop2} margin={margin}>
      {noFade &&
        <>
          {children}
        </>
      }
      {!noFade &&
        <Fade bottom cascade ssrFadeout>
          {children}
        </Fade>
      }
    </DivSection>
  )
}


const DivSection = styled.div`
  position: relative;
  margin-top: ${({ margin }) => margin ? margin : '0'}px;
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  ${({ hiddenX }) => hiddenX && css`
    overflow-x:hidden;
  `}
  ${({ twoColors }) => twoColors && css`
    background: linear-gradient(180deg, #202020 65%, #ffffff 35%);
  `};
  ${({ onTop }) => onTop && css`
    z-index:1;
  `};
  ${({ onTop2 }) => onTop2 && css`
    z-index:2;
  `};
`;