import React from 'react'
import styled from 'styled-components';
import { Container, Section, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function Faturacao({ medicContent }) {
  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.billing_label} backgroundColor="#fff">
        <LineColor />
        <Container md>
          <Wrapper top noHeight>
            <Content>
              <DivIntro>
                <Logo src="/static/images/icon_faturacao.svg" />
                <Intro
                  centerContainer
                  smallTitleColor="#77EDA6"
                  smallTitle={medicContent.content && medicContent.content.billing_label}
                  titleSize="H4"
                  title={medicContent.content && medicContent.content.billing_text1}
                  titleColor="#008FA6"
                  titleMaxWidth="412px"
                  titleTextAlign="center"
                  description={medicContent.content && medicContent.content.billing_text2}
                  descriptionSize={18}
                  descriptionMaxWidth="470px"
                  descriptionColor="#9AA1AC"
                  descriptionTextAlign="center"
                />
              </DivIntro>
              <Image src={medicContent.content && medicContent.content.billing_image} />
            </Content>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
const LineColor = styled.div`
  background: #B4FFD2;
  height: calc(50px + (220 - 50) * ((100vw - 375px) / (1920 - 375)));
  width: 100vw;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Image = styled.img`
  margin-top: 40px;
  width: 1200px;
  max-width: 100%;
  object-fit: contain;
`;
const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;