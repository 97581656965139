import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled, { css } from 'styled-components';
import { setLanguage } from '../../../utils/language';
import { store } from '../../../utils/store';
import useLang from '../../../utils/useLang';
import { Loading, Navbar } from '../../molecules';
import Home from '../../templates/home';
import Legal from '../../templates/legal';
import Medico from '../../templates/medico';
import Paciente from '../../templates/paciente';
import Aderir from '../../templates/aderir';
import ScrollToTop from './scroll';


function Main() {

  const { setEntryContent, setPatientContent, setMedicContent, setLegalContent, lang, setLang } = useContext(store);
  const [cookies, setCookie] = useCookies(['language']);
  const [path, setPath] = useState('');
  const [content, setContent] = useState(null);

  const setContentPages = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === 'patient') {
          setPatientContent(data[i]);
        } else if (data[i].key === 'medic') {
          setMedicContent(data[i]);
        } else if (data[i].key === 'entry') {
          setEntryContent(data[i]);
        } else if (data[i].key === 'terms') {
          setLegalContent(data[i]);
        }
      }
    }
  }

  const [settingsGet, { loading }] = useLazyQuery(
    gql`
      query {
        frontendPages {
          id
          key
          content
        }
        frontendLanguages {
          id
          name
          code
          default
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data && data.frontendLanguages && data.frontendLanguages.length > 0) {
          setContent(data);
        }
        if (data && data.frontendPages && data.frontendPages.length > 0) {
          setContentPages(data.frontendPages)
        }
      }
    }
  );

  useEffect(() => {
    settingsGet();
  }, [lang]);

  useEffect(() => {
    if (content && content.frontendLanguages && content.frontendLanguages.length > 0) {
      for (let i = 0; i < content.frontendLanguages.length; i++) {
        if (content.frontendLanguages[i].code === cookies.language) {
          if (content.frontendLanguages[i].default === true) {
            setLang(content.frontendLanguages[i].code);
            setLanguage(content.frontendLanguages[i].code);
            setCookie("language", content.frontendLanguages[i].code);
          }
        }
      }
    }
  }, [content]);

  let location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <Wrapper>
      {loading &&
        <Loading isLoading={loading} />
      }
      {(content && path !== '/') &&
        <Navbar languages={content && content.frontendLanguages} />
      }
      <Container full={path === '/'}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/paciente" component={Paciente} />
          <Route exact path="/medico" component={Medico} />
          <Route exact path="/legal" component={Legal} />
          <Route exact path="/aderir" component={Aderir} />
          <Redirect exact from="*" to="/paciente" />
        </Switch>
      </Container>
    </Wrapper >
  );
}
export default useLang(Main);


const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;
const Container = styled.div`
  display: flex;
  flex-flow: column;
  flex:1;
  ${({ full }) => full && css`
    height: 100vh;
    max-height: 100vh;
  overflow-y: scroll;
  `}
  @media screen and (max-width: 1200px){
    flex-flow: column;
  }
  @media screen and (max-width: 900px){
    ${({ full }) => full && css`
      height: 100%;
      max-height: unset;
    `}
  }
`;