import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { getLanguage, setLanguage } from './language';
import { store } from './store';

export default (Component) => {
    return () => {
        const { setLang } = useContext(store);

        const [cookies] = useCookies(['session']);

        if ((cookies.language && cookies.language !== '') && !getLanguage()) {
            setLanguage(cookies.language);
            setLang(cookies.language);
        }

        if (getLanguage()) {
          return <Component />
        } else {
            setLanguage('pt');
            setLang('pt');
        }

        return null;
    }
}