import React from 'react'
import styled from 'styled-components';
import { Section, Text18 } from '../../atoms';
import { Intro } from '../../molecules';

export default function Appointment({ patientContent }) {

  if (patientContent) {
    return (
      <Section className={patientContent.content && patientContent.content.appointment_label} backgroundColor="#fff">
        <Container>
          <ItemLeft>
            <Logo src="/static/images/icon_marcacao.svg" />
            <Intro
              smallTitleColor="#77EDA6"
              smallTitle={patientContent.content && patientContent.content.appointment_label}
              titleSize="H4"
              title={patientContent.content && patientContent.content.appointment_text1}
              titleColor="#008FA6"
              titleMaxWidth="356px"
              description={patientContent.content && patientContent.content.appointment_text2}
              descriptionSize={18}
              descriptionMaxWidth="356px"
              descriptionColor="#9AA1AC"
            />
            <DivOptions>
              <DivOption>
                <NumberBadge>
                  <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: '3' }} />
                </NumberBadge>
                <Text18 color="#3B3F45" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.appointment_text3 }} />
              </DivOption>
              <Line />
              <DivOption>
                <NumberBadge>
                  <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: '4' }} />
                </NumberBadge>
                <Text18 color="#3B3F45" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.appointment_text4 }} />
              </DivOption>
              <Line />
              <DivOption>
                <NumberBadge>
                  <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: '5' }} />
                </NumberBadge>
                <Text18 color="#3B3F45" dangerouslySetInnerHTML={{ __html: patientContent.content && patientContent.content.appointment_text5 }} />
              </DivOption>
            </DivOptions>
          </ItemLeft>
          <ItemRight>
            <Image src={patientContent.content && patientContent.content.search_image} />
          </ItemRight>
        </Container>
      </Section>
    )
  }
  return null;
}

const Container = styled.div`
  display:flex;
  flex-flow: row;
  @media screen and (max-width: 900px){
    flex-flow: column;
  }
`;
const ItemLeft = styled.div`
  padding: calc(30px + (124 - 30) * ((100vw - 375px) / (1920 - 375))) calc(15px + (128 - 15) * ((100vw - 375px) / (1920 - 375)));
  max-width: 50%;
  box-sizing: border-box;
  flex:1;
  display:flex;
  flex-flow: column;
  background: #FBFBFB;
  @media screen and (max-width: 900px){
    max-width: 100%;
  }
`;
const Image = styled.img`
  width: 420px;
  max-width: 100%;
  max-height: 652px;
  object-fit: contain;
`;
const ItemRight = styled.div`
  padding: calc(30px + (124 - 30) * ((100vw - 375px) / (1920 - 375))) calc(15px + (128 - 15) * ((100vw - 375px) / (1920 - 375)));
  max-width: 50%;
  box-sizing: border-box;
  flex: 1;
  display:flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: #B4FFD2;
  @media screen and (max-width: 900px){
    max-width: 100%;
  }
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;
const DivOptions = styled.div`
  margin-top: 32px;
  display: flex;
  flex-flow: column;
`;
const DivOption = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;
const NumberBadge = styled.div`
  width: 64px;
  height: 64px;
  background: #77EDA6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  border-radius: 50%;
  overflow: hidden;
  @media screen and (max-width: 900px){
    margin-right: 16px;
  }
`;
const Line = styled.div`
  border-left: 1.5px dashed #008FA6;
  height: 36px;
  margin-left: 32px;
`;