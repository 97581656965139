import React from 'react'
import { Container, Section, Text14, Wrapper } from '../../atoms'
import styled from 'styled-components'
import { Intro } from '../../molecules'

export default function Notificacoes({ medicContent, toogleForm }) {

  if (medicContent) {
    return (
      <Section className={medicContent.content && medicContent.content.notifications_label} backgroundColor="#FBFBFB">
        <Container lg>
          <Wrapper top noHeight>
            <DivIntro>
              <Logo src="/static/images/icon_notificacoes.svg" />
              <Intro
                centerContainer
                smallTitleColor="#77EDA6"
                smallTitle={medicContent.content && medicContent.content.notifications_label}
                titleSize="H4"
                title={medicContent.content && medicContent.content.notifications_text1}
                titleColor="#008FA6"
                titleMaxWidth="412px"
                titleTextAlign="center"
                description={medicContent.content && medicContent.content.notifications_text2}
                descriptionSize={18}
                descriptionMaxWidth="470px"
                descriptionColor="#9AA1AC"
                descriptionTextAlign="center"
              />
              <Button onClick={ () => toogleForm()}>
                <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: medicContent.content.notifications_button_text }} />
              </Button>
              <LaptopImage src={medicContent.content && medicContent.content.notifications_image} />
            </DivIntro>
          </Wrapper>
        </Container>
      </Section>
    )
  }
  return null;

}

const DivIntro = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const Logo = styled.img`
  max-width: 20px;
  max-height: 20px;
  object-fit: contain;
  margin-bottom: 16px;
`;
const Button = styled.div`
  margin-top: 36px;
  width: 176px;
  height: 40px;
  background: #77EDA6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    background: #008FA6;
  }
`;
const LaptopImage = styled.img`
  margin: 32px auto 0 auto;
  width: 1300px;
  max-width: 100%;
`;